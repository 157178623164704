<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
        OthercommonTrendController {{perf}}
				<DataTable :value="othercommontrends" class="p-datatable-sm"
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
          dataKey="id" :paginator="true" :rows="25"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :globalFilterFields="['oname', 'ocd']"
					selectionMode="single"
          v-model:expandedRows="expandedRows"
          :loading="loading">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
          <Column :expander="true" headerStyle="width: 3rem" />
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="oname" header="クラス名" :sortable="true"></Column>
					<Column field="ocd" header="ocd" :sortable="true"></Column>
					<Column field="meigaras_cnt" header="銘柄数" :sortable="true"></Column>
          <Column field="trendchart_file" header="時価総額トレンド">
            <template #body="slotProps">
              <img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.trendchart_file" :alt="slotProps.data.trendchart_file"/>
            </template>
          </Column>
          <Column field="v1d_mean" header="株価前日比(1Dμ)" :sortable="true">
            <template #body="slotProps">
              <div :class="v1dmean_priceClass(slotProps.data)">
                {{slotProps.data.v1d_mean}}
              </div>
            </template>
          </Column>
          <Column field="v2d_mean" header="株価前日比(2Dμ)" :sortable="true">
            <template #body="slotProps">
              <div :class="v2dmean_priceClass(slotProps.data)">
                {{slotProps.data.v2d_mean}}
              </div>
            </template>      
          </Column>     
          <Column field="v3d_mean" header="株価前日比(3Dμ)" :sortable="true">
            <template #body="slotProps">
              <div :class="v3dmean_priceClass(slotProps.data)">
                {{slotProps.data.v3d_mean}}
              </div>
            </template>      
          </Column>            
          <Column field="v5d_mean" header="株価前日比(5Dμ)" :sortable="true">
            <template #body="slotProps">
              <div :class="v5dmean_priceClass(slotProps.data)">
                {{slotProps.data.v5d_mean}}
              </div>
            </template>      
          </Column>           
          <Column field="v1d_std" header="株価前日比(1Dσ)" :sortable="true"></Column>       
          <Column field="v2d_std" header="株価前日比(2Dσ)" :sortable="true"></Column>
          <Column field="v3d_std" header="株価前日比(3Dσ)" :sortable="true"></Column>
          <Column field="v5d_std" header="株価前日比(5Dσ)" :sortable="true"></Column>   
          <Column field="v1d_volume_mean" header="出来高前日比(1Dμ)" :sortable="true"></Column>
          <Column field="v2d_volume_mean" header="出来高前日比(2Dμ)" :sortable="true"></Column>
          <Column field="v3d_volume_mean" header="出来高前日比(3Dμ)" :sortable="true"></Column>
          <Column field="v5d_volume_mean" header="出来高前日比(5Dμ)" :sortable="true"></Column>        
          <Column field="odate" header="日付"></Column>     
          <Column field="otime" header="時刻"></Column>
          <template #expansion="slotProps">
            <div class="marketvaluetrend-subtable">
              <h5>クラス名：{{slotProps.data.oname}}</h5>
              <DataTable :value="slotProps.data.meigaras" responsiveLayout="scroll"
                selectionMode="single">
                <Column field="id" header="No" sortable></Column>
                <Column field="meigara_cd" header="銘柄コード" sortable></Column>
                <Column field="meigara_name_ja" header="銘柄名" sortable></Column>
                <Column field="m_trendchart_file" header="銘柄トレンド">
                  <template #body="slotProps">
                    <img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.m_trendchart_file" :alt="slotProps.data.m_trendchart_file"/>
                  </template>
                </Column>
                <Column field="m_v1d_mean" header="株価前日比(1Dμ)" :sortable="true">
                  <template #body="slotProps">
                    <div :class="mv1dmean_priceClass(slotProps.data)">
                      {{slotProps.data.m_v1d_mean}}
                    </div>
                  </template> 
                </Column>
                <Column field="m_v2d_mean" header="株価前日比(2Dμ)" :sortable="true">
                  <template #body="slotProps">
                    <div :class="mv2dmean_priceClass(slotProps.data)">
                      {{slotProps.data.m_v2d_mean}}
                    </div>
                  </template> 
                </Column>
                <Column field="m_v3d_mean" header="株価前日比(3Dμ)" :sortable="true">
                  <template #body="slotProps">
                    <div :class="mv3dmean_priceClass(slotProps.data)">
                      {{slotProps.data.m_v3d_mean}}
                    </div>
                  </template> 
                </Column>                
                <Column field="m_v5d_mean" header="株価前日比(5Dμ)" :sortable="true">
                  <template #body="slotProps">
                    <div :class="mv5dmean_priceClass(slotProps.data)">
                      {{slotProps.data.m_v5d_mean}}
                    </div>
                  </template> 
                </Column>                  
                <Column field="m_close" header="株価" :sortable="true"></Column>
                <Column field="m_volume" header="出来高" :sortable="true"></Column>
                <Column field="m_odate" header="日付"></Column>     
                <Column field="m_otime" header="時刻"></Column>
              </DataTable>
            </div>
          </template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import othercommonTrendService from '@/service/OthercommonTrendService';

export default {
	data() {
		return {
			othercommontrends: null,
			filters1: {},
      expandedRows: [],
      loading: false,
      perf: null,
		}
	},
	othercommonTrendService: null,
	created() {
		this.othercommonTrendService = new othercommonTrendService();
		this.initFilters1();
	},
	mounted() {
    this.loading = true;
    this.othercommonTrendService.getAll('V0')
			.then(d => {
				this.othercommontrends = d.data;
        this.loading = false; 
				this.perf = d.perf;
			}
  );	  
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		},
    v1dmean_priceClass(data) {
      return [
        {
          'positive': data.v1d_mean > 0,
          'negative': data.v1d_mean < 0,
          'neutral': data.v1d_mean == 0,
        }
      ];
    },
    v2dmean_priceClass(data) {
      return [
        {
          'positive': data.v2d_mean > 0,
          'negative': data.v2d_mean < 0,
          'neutral': data.v2d_mean == 0,
        }
      ];
    },
    v3dmean_priceClass(data) {
      return [
        {
          'positive': data.v3d_mean > 0,
          'negative': data.v3d_mean < 0,
          'neutral': data.v3d_mean == 0,
        }
      ];
    },
    v5dmean_priceClass(data) {
      return [
        {
          'positive': data.v5d_mean > 0,
          'negative': data.v5d_mean < 0,
          'neutral': data.v5d_mean == 0,
        }
      ];
    },
    mv1dmean_priceClass(data) {
      return [
        {
          'positive': data.m_v1d_mean > 0,
          'negative': data.m_v1d_mean < 0,
          'neutral': data.m_v1d_mean == 0,
        }
      ];
    },
    mv2dmean_priceClass(data) {
      return [
        {
          'positive': data.m_v2d_mean > 0,
          'negative': data.m_v2d_mean < 0,
          'neutral': data.m_v2d_mean == 0,
        }
      ];
    },
    mv3dmean_priceClass(data) {
      return [
        {
          'positive': data.m_v3d_mean > 0,
          'negative': data.m_v3d_mean < 0,
          'neutral': data.m_v3d_mean == 0,
        }
      ];
    },
    mv5dmean_priceClass(data) {
      return [
        {
          'positive': data.m_v5d_mean > 0,
          'negative': data.m_v5d_mean < 0,
          'neutral': data.m_v5d_mean == 0,
        }
      ];
    },
	}
}
</script>

<style scoped>
@import '../assets/prod/styles/prices.scss';

.trendchart-img {
  opacity: 0.8;
  border: solid 1px #cccccc;
  border-radius: 5px;  
  width: 136px;
  height: 35px;
}
.marketvaluetrend-subtable {
    padding: 2rem;
}
</style>
